import { EditorSdkAdapter } from '@wix/bookings-adapter-editor-sdk';
import { isBookingInstalled } from '../utils/editor-sdk-actions';
import {HandleActionPayload, PRICING_PLANS_DEF_ID} from '../constants';
import { migrateToOOI } from '../utils/ooi-migration/ooi-migration';
import {
  EditorSDK,
  EditorReadyOptions,
} from '@wix/platform-editor-sdk';
import { EditorScriptApi } from '../api/api';
import { Translations } from '../utils/translations';
import {migrateCart} from "../utils/ecom-migration/ecom-actions";

export type MigrateAction = {
  payload: HandleActionPayload;
  appToken: string;
  editorSdk: EditorSDK;
  editorScriptApi: EditorScriptApi;
  editorReadyCallback: () => boolean;
  isBookingsOnEcom: boolean;
  instance: string;
  editorOptions: EditorReadyOptions;
  displayStepsBar?: {
    editorTranslation: Translations;
  };
}

export const migrateAction = async ({
  payload,
  appToken,
  editorSdk,
  editorScriptApi,
  editorReadyCallback,
  isBookingsOnEcom,
  instance,
  editorOptions,
  displayStepsBar
}: MigrateAction) => {
  try {
    await editorSdk.document.transactions.runAndWaitForApproval(
      appToken,
      async () => {
        const isJustToInstallPricingPlans = payload?.withPricingPlans;
        const isOOIMigration = payload?.OOIMigration;
        const isCartMigration = payload?.CartMigration;
        const isOOICheckCanMigrate = payload?.OOICheckCanMigrate;
        const isEmptyScript = payload?.emptyScript;
        const isDontWaitForEditorReady = payload?.dontWaitForEditorReady;

        if (isEmptyScript || !(await isBookingInstalled(editorSdk))) {
          return;
        }

        if (!isDontWaitForEditorReady) {
          await editorScriptApi.sleepUntil(editorReadyCallback);
        }

        if (isCartMigration) {
          await migrateCart({
            editorSdk,
            editorScriptApi,
            instance
          });
        }

        if (isJustToInstallPricingPlans) {
          await editorSdk.document.tpa.add.application(appToken, {
            appDefinitionId: PRICING_PLANS_DEF_ID,
          });
        }
        if (isBookingsOnEcom && isOOIMigration) {
          if (
            !(await editorScriptApi.shouldMigrateEcom(
              isOOICheckCanMigrate,
            ))
          ) {
            throw new Error(
              `should not migrate - errorCode: ec083f47-92ef-44f1-a073-714dc3a1fc67`,
            );
          }

          await migrateToOOI({
            editorSDK: editorSdk,
            appToken,
            editorScriptApi,
            instance,
            handleActionPayload: payload,
            editorOptions,
            displayStepsBar
          });
        }
      }
    );
  } catch (e) {
    const errorMessage = `${
      e?.message ? e.message : JSON.stringify(e)
    } - errorCode: MIGRATE_ACTION`;
    throw new Error(errorMessage);
  }
}
